import { createStore } from 'vuex'



var store = createStore({
    state() {
        return {



        }
    },
    mutations: {

    }
})

export default store