import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/css/global.css'
import '@/assets/js/disable-enlarge.js'
import Global from '@/assets/js/global.js'
import Common from '@/assets/js/common.js'
import { Button } from 'vant'
import 'vant/lib/index.css';
import router from './router/index.js'
import store from './store/index.js'
import i18n from '@/assets/language'
import { PullRefresh, List } from 'vant';

// import Vconsole from 'vconsole';
// let vconsole = new Vconsole;
// vconsole



const OjbApp = createApp(App)
OjbApp.config.globalProperties.$global = Global
OjbApp.config.globalProperties.$common = Common

OjbApp
    .use(router)
    .use(i18n)
    .use(store)
    .use(Button)
    .use(PullRefresh)
    .use(List)
    .mount('#app')