import { createRouter, createWebHistory } from "vue-router";

const routes = [{
        path: "/short",
        name: "short",
        component: () =>
            import ("@/views/short/index.vue"),
    },
    // {
    //     path: "/search",
    //     name: "tag",
    //     component: () =>
    //         import ("@/views/tag/index.vue"),
    // },
    {
        path: "/",
        name: "tag",
        component: () =>
            import ("@/views/tag/index.vue"),
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from) => {

})
router.afterEach((to, from) => {

})

export default router