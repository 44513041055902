import { showToast } from 'vant';
import useClipboard from 'vue-clipboard3'


const common = {
    test: () => {
        alert(321)
    },
    currentTime: (time = 0) => {

        let date = new Date(time * 1000); // 参数需要毫秒数，所以这里将秒数乘于 1000

        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        let h = (date.getHours() < 10 ? '0' + (date.getHours() + 1) : (date.getHours() + 1)) + ':';
        let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();

        return Y + M + D + h + m + s;
    },

    copy: (text) => {
        if (!text) {
            showToast('text is empty!');
            return
        }
        const { toClipboard } = useClipboard()
        try {
            toClipboard(text)
            showToast('success')
        } catch (e) {
            showToast('failed')
        }
    },

    isEmpty(data) {

        if (
            data === 0 ||
            data === '0' ||
            data === 0.0 ||
            data === '0.0' ||
            data === undefined ||
            data === "undefined" ||
            data === null ||
            data === '' ||
            data === false ||
            JSON.stringify(data) == '{}' ||
            JSON.stringify(data) == '[]'
        ) {
            return true;
        } else {
            return false;
        }
    },


    verifyParams(params) {

        if (common.isEmpty(params)) {
            showToast('Please input frist!');
            throw SyntaxError();
        }

        for (let index in params) {
            if (common.isEmpty(params[index])) {
                showToast(index + ' is empty!');
                throw SyntaxError();
            }
        }
    },




    isAndroid: () => {
        let u = navigator.userAgent;
        return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
    },

    tranSec: (s) => {
        //算法：将秒数除以60，然后下舍入，既得到分钟数
        var h;
        h = Math.floor(s / 60);
        //计算秒
        //算法：取得秒%60的余数，既得到秒数
        s = s % 60;
        //将变量转换为字符串
        h += '';
        s += '';
        //如果只有一位数，前面增加一个0
        h = (h.length == 1) ? '0' + h : h;
        s = (s.length == 1) ? '0' + s : s;
        return h + ':' + s;
    },



}

export default common;