import { createI18n } from 'vue-i18n'
import messages from './languageImport.js'


let langDefault = 'zh';


localStorage.setItem('yydgLanguage', langDefault)

// 默认语言
const i18n = createI18n({
    locale: langDefault, //默认显示的语言 
    messages
})

export default i18n;